import WC from 'wc-util'

import template from './ts-panel-group.tpl'

class TsPanelGroup extends HTMLElement {

  registerPanel(desc_panel){
    this.panels.push(desc_panel);
    this.redraw();
  }

  deregisterPanel(desc_panel){
    WC.array_delete(this.panels, desc_panel);
    this.redraw();
  }

  redraw(){
    var panel_list_el = this.panel_dock.querySelector(".ts-panel-list");
    panel_list_el.innerHTML = "";

    this.panels.forEach(function(p){
      var link = document.createElement("li");
      link.innerHTML = '<icon>' +
             (p.windowmode != "hidden" ? 'check_box' : 'check_box_outline_blank') +
             '</icon>' +
             '<div>' +
             p.title +
             '</div>';
      link.panel = p;
      panel_list_el.appendChild(link);
    })
  }

  /* set the window mode for all registered panels */
  toggle_all(new_windowmode){
    this.panels.forEach(function(p){
      this.panels.forEach( function(p){p.windowmode = new_windowmode} );
    }.bind(this));
  }

  /* set a particular panel to windowmode=fullscreen */
  set_fullscreen(panel){
    var PANEL_FULLSCREEN_WINDOW_MODE = "fullscreen",
        current_fullscreen_panel = null;

    this.panels.forEach( function(p){
      if (p.windowmode == PANEL_FULLSCREEN_WINDOW_MODE){
        current_fullscreen_panel = p;
        p.windowmode = null;
      }
    });
    if (current_fullscreen_panel != panel){
      panel.windowmode = PANEL_FULLSCREEN_WINDOW_MODE;
    }
  }

  /* show/hide the full dropdown menu */
  toggle(){
    this.panel_dock.classList.toggle("expanded");
  }

  connectedCallback(){

    // deal with sub-panels
    this.panels = [];
    this.addEventListener("ts-resize", function(e){
      // do something? Make sure to test that it comes from a ts-panel
    })

    // UI
    WC.setup_dom_template(this, template);
    this.panel_dock = this.querySelector(".ts-panel-dock");

    this.render_panel_dock();

    // Events
    this.panel_dock.querySelector(".ts-panel-header").addEventListener("click", this.toggle.bind(this)) ;

    this.panel_dock.querySelector(".ts-panel-list").addEventListener("click", function(e){
      const panel = e.target.nodeName == "LI" ? e.target.panel : e.target.parentElement.panel

      panel.windowmode = panel.windowmode == "hidden" ? "" : "hidden";
      this.redraw();
    }.bind(this));

    this._register_click_to_toggle(".ts-panel-dock-hide-all", "hidden");
    this._register_click_to_toggle(".ts-panel-dock-minimize-all", "minimized");
    this._register_click_to_toggle(".ts-panel-dock-restore-all", "");

  }

  render_panel_dock(){
    var alt_dock_location = document.querySelector(this.getAttribute("render-dock-to")); // null if no such attribute
    if (alt_dock_location){
      alt_dock_location.appendChild(this.panel_dock);
    }
  }

  _register_click_to_toggle(selector, target_window_mode){
    this.panel_dock.querySelector(selector).addEventListener("click", function(e){
      this.toggle_all(target_window_mode);
      e.preventDefault();
      this.redraw();
    }.bind(this));
  }
};


customElements.define('ts-panel-group', TsPanelGroup);