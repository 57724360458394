import WC from 'wc-util'

import { default as template } from './ts-panel.tpl';


class TsPanel extends HTMLElement {

  connectedCallback(){

    WC.setup_dom_template(this, template);

    var panel = this;

    // set the title and the window-icon
    panel.title_text = this.title;
    if (this.getAttribute("icon")) panel.querySelector(".ts-panel-icon").innerHTML = this.getAttribute("icon");

    WC.select(this.children, "ts-panel-body:not([variant])").forEach(function(e){ e.setAttribute("variant", "normal") });

    // wire up windowmode changes
    this.window_modes = (this.getAttribute('windowmodes') || 'minimized fullscreen hidden').
                           split(" ").
                           filter(function(e){return e}); // kick out null and ""

    if (this.window_modes.indexOf("normal") == -1)  this.window_modes.unshift('normal');

    if (!this.windowmode) this.windowmode = 'normal'; // if not specified, default to 'normal' window mode

    // windowmode indicator
    if (!this.window_modes.includes("normal") || !this.window_modes.includes("minimized")){
      this.querySelector(".ts-panel-windowmode-indicator").style.display = "none";
    } else {
      this.querySelector(".ts-panel-windowmode-indicator").addEventListener("click", function(e){
        panel.windowmode = (panel.windowmode == "normal" ? "minimized" : "normal");
      })
    }

    if (this.window_modes.includes("fullscreen")){
      this.querySelector(".ts-panel-header").addEventListener("dblclick", function(e){
        panel.windowmode = (panel.windowmode == "normal" ? "fullscreen" : "normal");
      })
    }

    var has_additional_window_actions = (this.querySelector("slot[name=ts-panel-window-actions]").innerHTML.trim() != '');
    if (!has_additional_window_actions){
   // need to use MutationObservers here to turn back on if we get actions later on
   //   this.querySelector('.ts-panel-window-actions').style.display = 'none';
    }

    // prepare for wiring up variant changes
    var variant_ctrl = this.querySelector(".ts-panel-controls.ts-action-change-variant");

    variant_ctrl.style.display = 'none';
    this.other_variants = this.other_variants || []; // the variants that are not accessible by a direct action link in the panel-header
    this.num_registered_variants = this.other_variants.length;

    variant_ctrl.addEventListener("click", function(){
      var current_variant_idx = this.other_variants.indexOf(this.variant); // -1 if none
      this.variant = this.other_variants[(current_variant_idx + 1) % this.other_variants.length];
    }.bind(this))

    this.panel_group = WC.closest(this, "ts-panel-group");
    this.panel_group && this.panel_group.registerPanel(this);

  }

  disconnectedCallback(){
    this.panel_group && this.panel_group.deregisterPanel(this);
  }

  registerPanelBody(panel_body){
    var new_variant = panel_body.getAttribute("variant");

    this.num_registered_variants += 1;

    if (!this.variant || this.variant == new_variant){
      this.variant = new_variant;
    }

    // set up panel actions to switch to this panel-body
    if (panel_body.getAttribute("icon")){
      var action_icon = document.createElement("icon");
      action_icon.className = "ts-panel-controls ts-action ts-panel-change-variant";
      action_icon.innerHTML = panel_body.getAttribute("icon");
      action_icon.setAttribute("variant", new_variant);
      var icontitle;
      if (icontitle = panel_body.getAttribute("icon_title")){ action_icon.setAttribute("title", icontitle) };
      action_icon.addEventListener("click", function(){
        this.variant = new_variant;
      }.bind(this));

      this.querySelector(".ts-panel-header .ts-panel-actions").appendChild(action_icon);
    } else {
      this.other_variants = this.other_variants || [];
      this.other_variants.push(new_variant);
    }

    var change_variant_ctrl = this.querySelector(".ts-panel-controls.ts-action-change-variant");
    if (this.num_registered_variants > 1 && change_variant_ctrl.style.display == 'none'){
      change_variant_ctrl.style.display = '';
    }

    // remove the @title attribute that would cascade from the <ts-panel>
    if(!panel_body.hasAttribute("title")){
      panel_body.title = "";
    }
  }

  get windowmode(){
    return this.getAttribute("windowmode");
  }
  set windowmode(mode){
    var old_mode = this.windowmode,
        new_mode = (mode == "" ? "normal" : mode || "normal");
    if (old_mode != mode){
      this.querySelector(".ts-panel-window-actions").close(); // close windowmode selector
      this.setAttribute("windowmode", mode);
      WC.fireEvent(this, "ts-resize", {old_mode: old_mode, mode: mode}); // notify listeners like ts-panel-group
    }
  }

  set title_text(new_title_text){
    this.querySelector(".ts-panel-title").innerHTML = new_title_text;
  }

  get windowmodes(){
    return this.window_modes;
  }

  get variant(){
    return this.getAttribute("variant");
  }

  set variant(new_variant){
    var actual_active_body = WC.select(this.children, ".active")[0],
        old_variant = actual_active_body && actual_active_body.getAttribute("variant");
    if (old_variant != new_variant){
      this.setAttribute("variant", new_variant); // for styling purposes, ad the current variant to the panel element
      if (actual_active_body){
        actual_active_body.classList.remove("active"); // deactivate the old body
      }
      WC.select(this.children, "[variant='" + new_variant + "']").forEach(function(n){ // activate the new body
        n.classList.add("active");
        n.show();
      });
      WC.fireEvent(this, "ts-content-change", {old_variant: old_variant, variant: new_variant}); // notify listeners like ts-panel-group
    }
  }

}

customElements.define('ts-panel', TsPanel);
