import WC from 'wc-util'

import { default as template } from './ts-dropdown.tpl';


class TsDropdown extends HTMLElement {

  connectedCallback(){

    WC.setup_dom_template(this, template);

    if (this.hasAttribute("locktoggle")){
          this.querySelector(".ts-dropdown-title").addEventListener("click", function(){
            this.toggle();
          }.bind(this));
        }
  }

  toggle(){
    this.classList.toggle("expanded");
  }

  close(){
    this.classList.remove("expanded");
  }
}

customElements.define('ts-dropdown', TsDropdown);