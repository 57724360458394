export { setupEditor, editorContent, setEditorContent, editorBody, copy }

import { default as Tiny } from "../../../vendor/assets/tinyeditor_201002/tiny.editor.js"

const defaultControls = [
  'bold','italic','underline','strikethrough','|','subscript','superscript','|',
  'orderedlist','unorderedlist','|','outdent','indent','|','leftalign',
  'centeralign','rightalign','blockjustify','|','unformat','|','undo','redo','n',
  'font','size','style','|','image','hr','link','unlink','|','cut','copy','paste','print'
]

function setupEditor(textArea, controls){
  const new_tiny_editor = new Tiny.editor.edit('tiny_editor' + crypto.randomUUID().replaceAll('-','_'), {
    id:           textArea,
    controls:     controls || defaultControls,
    styles:       [['Style', ''], ['Paragraph', '<p>'], ['Header 1', '<h1>'], ['Header 2', '<h2>'], ['Header 3', '<h3>'], ['Preformatted', '<pre>']],
    controlclass: 'tinyeditor-control',
    width:        "100%",
    css:          "body{white-space:pre-wrap;font-family:'Open Sans',sans-serif;}",
    footer:       true,
    toggle:       { text:'plain text', activetext:'rich text', cssclass:'toggle' }
  })

  textArea.form?.addEventListener(
    "ajax:before",
    (_event) => new_tiny_editor.post()
  )
  return new_tiny_editor;
}

function editorContent(tinyEditor){
  return tinyEditor.e.body.innerHTML
}

function setEditorContent(tinyEditor, newContent){
  tinyEditor.e.body.innerHTML = newContent
}

// if you have to wire up any events on the editor itself
function editorBody(tinyEditor){
  return tinyEditor.e.body
}

function copy(tinyEditor){
  tinyEditor.e.execCommand("selectAll")
  tinyEditor.e.execCommand("copy")
}
