

require("topsail_cruddy_views/src/single_page")

import("ts-ujs-dom-update")

import Rails from 'rails-ujs';
Rails.start();
window.Rails = Rails;

import "../controllers" // import "@stimulus" controllers

import 'built-in-element'
import 'wc-util'
import 'ts-form'
import 'ts-loadable'
import 'ts-select2'
import 'ts-dropdown'
import 'ts-panel'
import {TsTooltip} from '@topsail/ts-tooltip'
window.customElements.define('ts-tooltip', TsTooltip);
import 'ts-ujs-dom-update'

import {default as jQuery} from "jquery"
window.$ = jQuery;
window.jquery = jQuery;
window.jQuery = jQuery;
