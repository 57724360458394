// Casee specific wrapper for the Tiny.editor library.
import { Controller } from "stimulus"
import { setupEditor as setupTinyEditor, copy as copyEditToClipboard } from "./tiny_editor"

export default class extends Controller {
  static targets = ["textArea"]
  static values = {
    controls: Array
  }

  connect(){
    this.editor = setupTinyEditor(
                    this.textAreaTarget,
                    this.hasControlsValue ? this.controlsValue : null
                  )
  }

  copyToClipboard(evt){
    evt.preventDefault()
    copyEditToClipboard(this.editor)
  }
}
