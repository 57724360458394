import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggle" ]

  toggle(){
    const element = this.toggleTarget;
    element.style.display = (element.style.display == 'none' ? 'inherit' : 'none');
  }
}
