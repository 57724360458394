import WC from 'wc-util'

class TsPanelBody extends HTMLElement {

  connectedCallback() {
    this.panel = WC.closest(this, "ts-panel");

    var href = this.getAttribute("href");

    if (href != null){ // could even be empty string!
      this.innerHTML = "<ts-loadable href='" + href + "' lazyload='true'></ts-loadable>";
    } else if (WC.select(this.children, "ts-loadable")[0]){
      // nested ts-loadable elements are not parsed and turned into a full-featured instance (with a load method).
      // Forcing this by re-adding the content, that triggers all the instantiation....
      this.innerHTML = this.innerHTML;
    }

    this.panel && this.panel.registerPanelBody(this);
  }

  show(){
    var loadable = WC.select(this.children, "ts-loadable")[0];
    if (loadable){
      loadable.load();
    }
  }
};

customElements.define('ts-panel-body', TsPanelBody);